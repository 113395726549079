import { Box, Center, Flex, Grid, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useRef } from "react";
import { InView } from "react-intersection-observer";
import HeroComponent from "../components/HeroComponent";
import Layout from "../components/Layout";
import useHover from "../hooks/useHover";

const MotionBox = motion(Box);
const MotionText = motion(Text);

const WhyAeviasPage = ({ location }) => {
  const [heroTextRef, isHeroTextHovered] = useHover();
  const scrollHere = useRef(null);

  return (
    <Layout location={location}>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        justifyContent="center"
        minH="85vh"
        maxH="672px"
      >
        <Box
          position="relative"
          zIndex="5"
          px={{ base: "24px", md: "6rem" }}
          textAlign="center"
        >
          <Box
            display="flex"
            alignItems="center"
            color="#FFFFFF"
            fontSize={{ base: "64px", md: "96px", lg: "120px" }}
          >
            <motion.div
              animate={{ opacity: 1, transition: { duration: 1.2 } }}
              initial={{ opacity: 0 }}
            >
              <Text
                as="h1"
                color="#FFFFFF"
                fontSize={{ base: "64px", md: "96px", lg: "120px" }}
                fontFamily="'Antonio'"
                mr={{ base: 4, md: 6, lg: 8 }}
              >
                Why
              </Text>
            </motion.div>

            <motion.span
              ref={heroTextRef}
              onClick={() => {
                scrollHere.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                  top: 600,
                  behavior: "smooth",
                });
              }}
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0.4 },
              }}
              initial={{ opacity: 0 }}
              whileHover={{
                y: -12,
                transition: {
                  ease: "easeInOut",
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
              }}
            >
              Aevias?
            </motion.span>
          </Box>
          {/* <Box
            display="flex"
            justifyContent="center"
            fontSize={{ base: "20px", md: "24px", lg: "32px" }}
          >
            <motion.span
              animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 1.2, delay: 0.8, ease: "easeInOut" },
              }}
              initial={{ opacity: 0, y: -80 }}
              style={{
                position: "relative",
                fontFamily: "Montserrat",

                color: "#ffffff",
                fontWeight: 600,
              }}
            >
              Hello, we're Aevias!
            </motion.span>
          </Box> */}
        </Box>
        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.5"
            backgroundColor={isHeroTextHovered ? "secondary.700" : "#000000"}
            backgroundBlendMode={isHeroTextHovered ? "multiply" : "initial"}
            transition="2s all ease-in-out"
          />
          <HeroComponent>
            <Box
              height="100%"
              backgroundImage="url('/img/why-aevias-hero.jpg')"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            />
          </HeroComponent>
        </Box>
      </Box>

      <Box ref={scrollHere} as="section">
        <InView triggerOnce threshold={0.4}>
          {({ inView, ref }) => (
            <Box
              as="section"
              py="8rem"
              bg="#F4F4FF"
              minH={{ base: "calc(100vh - 113px)", md: "initial" }}
              display="flex"
              alignItems="center"
            >
              <Center ref={ref} className="wrapper-xl">
                {inView && (
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: "4rem", opacity: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      as="h2"
                      fontSize={{ base: "24px", md: "28px", lg: "32px" }}
                      lineHeight={{ base: "32px", md: "36px", lg: "40px" }}
                      color="secondary.500"
                      my="auto"
                      fontFamily="'Montserrat'"
                      fontWeight="600"
                      mb="6"
                    >
                      At Aevias, we believe that connected, engaged, and
                      motivated people are the cornerstone of productivity and
                      success. We value the transformative power of connections,
                      fostering creativity, community, and growth.
                    </Text>
                    <Text
                      as="p"
                      color="#000000"
                      fontSize="18px"
                      lineHeight="28px"
                      mb="12"
                    >
                      By understanding our clients’ needs - even anticipating
                      them before they arise - we deliver meaningful solutions
                      that resonate. Established in 2018, we have harnessed
                      decades of collective experience to create engaging and
                      impactful experiences for brands worldwide.
                    </Text>

                    <Grid
                      justifyContent="center"
                      textAlign="center"
                      alignItems="center"
                      gridTemplateColumns={{
                        base: "none",
                        md: "1fr 1fr 1fr 1fr",
                      }}
                      gridTemplateRows={{
                        base: "auto auto auto auto",
                        md: "none",
                      }}
                      gridColumnGap={{ base: 0, md: "4rem" }}
                      gridRowGap={{ base: "40px", md: 0 }}
                    >
                      <Flex justifyContent="center" flexDirection="column">
                        <Text
                          as="h4"
                          fontSize={{ base: "40px", md: "40px" }}
                          lineHeight={{ base: "40px", md: "40px" }}
                          fontWeight="bold"
                          color="secondary.500"
                          alignSelf="center"
                        >
                          130 years
                        </Text>
                        <Text
                          marginTop="0.75rem"
                          as="p"
                          fontSize={{ base: "20px", md: "20px" }}
                          lineHeight={{ base: "24px", md: "24px" }}
                          fontWeight="semibold"
                        >
                          Team
                          <br />
                          Experience
                        </Text>
                      </Flex>

                      <Flex
                        rowGap="1rem"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <Text
                          as="h4"
                          fontSize={{ base: "40px", md: "40px" }}
                          lineHeight={{ base: "40px", md: "40px" }}
                          fontWeight="bold"
                          color="secondary.500"
                          alignSelf="center"
                        >
                          300+
                        </Text>
                        <Text
                          as="p"
                          marginTop="0.75rem"
                          fontSize={{ base: "20px", md: "20px" }}
                          lineHeight={{ base: "24px", md: "24px" }}
                          fontWeight="semibold"
                        >
                          In-Person Events Produced
                        </Text>
                      </Flex>

                      <Flex
                        rowGap="1rem"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <Text
                          as="h4"
                          fontSize={{ base: "40px", md: "40px" }}
                          lineHeight={{ base: "40px", md: "40px" }}
                          fontWeight="bold"
                          color="secondary.500"
                          alignSelf="center"
                          cursor="pointer"
                        >
                          75+
                        </Text>
                        <Text
                          as="p"
                          marginTop="0.75rem"
                          fontSize={{ base: "20px", md: "20px" }}
                          lineHeight={{ base: "24px", md: "24px" }}
                          fontWeight="semibold"
                        >
                          Digital Events Produced
                        </Text>
                      </Flex>

                      <Flex justifyContent="center" flexDirection="column">
                        <Text
                          as="h4"
                          fontSize={{ base: "40px", md: "40px" }}
                          lineHeight={{ base: "40px", md: "40px" }}
                          fontWeight="bold"
                          color="secondary.500"
                          alignSelf="center"
                        >
                          40+
                        </Text>
                        <Text
                          as="p"
                          marginTop="0.75rem"
                          fontSize={{ base: "20px", md: "20px" }}
                          lineHeight={{ base: "24px", md: "24px" }}
                          fontWeight="semibold"
                        >
                          Cities Worked In
                        </Text>
                      </Flex>
                    </Grid>
                  </motion.div>
                )}
              </Center>
            </Box>
          )}
        </InView>
      </Box>

      <Box pb="6rem" as="section">
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              className="wrapper-xl"
              position="relative"
              ref={ref}
              py="6rem"
            >
              <MotionBox
                position="absolute"
                width="100%"
                backgroundColor="#C3F9E0"
                right="0"
                left="0"
                top="4rem"
                bottom="0"
                className="wrapper-md"
              />
              {inView && (
                <Box
                  position="relative"
                  zIndex="2"
                  mx={{ base: "24px", md: 0 }}
                >
                  <Box display="flex" flexDir="column">
                    <Text as="h2" className="ae-title-two" mb="6">
                      “The singular purpose of Aevias is to create unforgettable
                      and impactful events that drive your business toward
                      growth.”
                    </Text>
                    <Box display="flex" flexDir="column">
                      <Text color="#000000" className="ae-title-two">
                        -George Bartel
                      </Text>
                      <Text className="ae-desc-one">Aevias President</Text>
                    </Box>

                    <Box pb="8rem" as="section">
                      <Box mt="4rem" as="section" className="wrapper-xl">
                        <InView triggerOnce threshold={0.5}>
                          {({ inView, ref }) => (
                            <Box position="relative" ref={ref}>
                              {inView && (
                                <Box position="relative" zIndex="2">
                                  <Box display="flex" flexDir="column">
                                    <Text
                                      as="h2"
                                      className="ae-title-one"
                                      mb="6"
                                    >
                                      Our People
                                    </Text>
                                    <Text
                                      className="ae-desc-one"
                                      mb="16"
                                      width="80%"
                                    >
                                      Our strength is our team and our
                                      relationships. We're designers,
                                      strategists, ideators, creators and
                                      producers. Meet our people.
                                    </Text>
                                    <Grid
                                      gridTemplateColumns={{
                                        base: "none",
                                        lg: "repeat(3, 1fr)",
                                      }}
                                      gridTemplateRows={{
                                        base: "repeat(3, auto)",
                                        lg: "none",
                                      }}
                                      gridColumnGap={{ base: 0, lg: "1rem" }}
                                      gridRowGap={{ base: "4rem", lg: 0 }}
                                      marginBottom="4rem"
                                    >
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-george.png"
                                          alt="George Bartel - aevias president"
                                        />
                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          George Bartel
                                        </Text>
                                        <Text className="ae-desc-one">
                                          President
                                        </Text>
                                      </Box>
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/no-photo.png"
                                          alt="Michelle Clarke - Senior Director of Events"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Michelle Clarke
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Senior Director of Events
                                        </Text>
                                      </Box>
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-cari.png"
                                          alt="George Bartel - aevias president"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Cari Winsor CMP,CEMM
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Director, Client Services
                                        </Text>
                                      </Box>

                                      {/* <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-missy.png"
                                          alt="Missy Preston - vice president"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Missy Preston
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Vice President
                                        </Text>
                                      </Box> */}
                                      {/* <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-priscilla.png"
                                          alt="Priscilla Chan DES - Aevias Director, Client Services"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Priscilla Chan DES
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Director, Events
                                        </Text>
                                      </Box> */}
                                    </Grid>

                                    <Grid
                                      gridTemplateColumns={{
                                        base: "none",
                                        lg: "repeat(3, 1fr)",
                                      }}
                                      gridTemplateRows={{
                                        base: "repeat(3, auto)",
                                        lg: "none",
                                      }}
                                      gridColumnGap={{ base: 0, lg: "1rem" }}
                                      gridRowGap={{ base: "4rem", lg: 0 }}
                                      alignItems="center"
                                      marginBottom="4rem"
                                    >
                                      {/* <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-cari.png"
                                          alt="George Bartel - aevias president"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Cari Winsor CMP,CEMM
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Manager, Client Services — GTA
                                        </Text>
                                      </Box> */}
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-christine.png"
                                          alt="Missy Preston - vice president"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Christine Waschhofer
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Director, Travel Services
                                        </Text>
                                      </Box>
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-suzanne.png"
                                          alt="Suzanne Boyd - Director, Business Development"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Suzanne Boyd
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Director, Business Development
                                        </Text>
                                      </Box>
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-diana.png"
                                          alt="Diana Man - Senior Events Manager and Operations"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Diana Man
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Senior Events Manager and Operations
                                        </Text>
                                      </Box>
                                    </Grid>

                                    <Grid
                                      gridTemplateColumns={{
                                        base: "none",
                                        lg: "repeat(2, 1fr)",
                                      }}
                                      gridTemplateRows={{
                                        base: "repeat(2, auto)",
                                        lg: "none",
                                      }}
                                      gridColumnGap={{ base: 0, lg: "1rem" }}
                                      gridRowGap={{ base: "4rem", lg: 0 }}
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-isabella.png"
                                          alt="Isabella Leung - Event Specialist"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          Isabella Leung
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Event Specialist
                                        </Text>
                                      </Box>
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          src="/img/ae-david.png"
                                          alt="George Bartel - aevias president"
                                        />

                                        <Text
                                          mt="4"
                                          mb="2"
                                          className="ae-title-three"
                                        >
                                          David Fung
                                        </Text>
                                        <Text className="ae-desc-one">
                                          Accountant
                                        </Text>
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          )}
                        </InView>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </InView>
      </Box>

      <Box as="section">
        <Text as="h2" className="ae-title-one" textAlign="center">
          Our Values
        </Text>
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              mb={{ base: "40px", md: "8rem" }}
              mt={{ base: "24px", md: "4rem" }}
              minH={{ base: "calc(100vh - 113px)", md: "initial" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-xl">
                {inView && (
                  <Grid
                    display={{ base: "flex", md: "grid" }}
                    flexDir="column"
                    gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "40px" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box display="flex" justifyContent="center">
                      <img
                        draggable="false"
                        src="/img/ae-values-one.png"
                        alt="aevias values one"
                      />
                    </Box>
                    <Box>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                        marginBottom="12"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.4 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We value
                        </MotionText>{" "}
                        the power of human connections and relationships; this
                        is the foundation of how we approach everything we do.
                      </Text>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.8 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We listen
                        </MotionText>{" "}
                        to our clients, our supplier partners, our industry
                        peers to develop understanding so we may bring value to
                        their businesses. We work with integrity; we do what is
                        right.
                      </Text>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>

        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              my={{ base: "6rem", md: "8rem" }}
              minH={{ base: "calc(100vh - 113px)", md: "initial" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-xl">
                {inView && (
                  <Grid
                    display={{ base: "flex", md: "grid" }}
                    flexDir="column-reverse"
                    gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "80px" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                        marginBottom="12"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.4 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We believe
                        </MotionText>{" "}
                        in responsible business practices, and we embrace
                        opportunities to contribute to sustainability, our
                        community and humanity.
                      </Text>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.8 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We value our people.
                        </MotionText>{" "}
                        We encourage curiosity and empower through learning and
                        professional development.
                      </Text>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <img
                        draggable="false"
                        src="/img/ae-values-two.png"
                        alt="aevias values two"
                      />
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>

        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              my={{ base: "40px", md: "8rem" }}
              mb="8rem"
              minH={{ base: "calc(100vh - 113px)", md: "initial" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-xl">
                {inView && (
                  <Grid
                    display={{ base: "flex", md: "grid" }}
                    flexDir="column"
                    gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "80px" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box display="flex" justifyContent="center">
                      <img
                        draggable="false"
                        src="/img/ae-values-three.png"
                        alt="aevias values three"
                      />
                    </Box>
                    <Box>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.8 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We have a duty of care
                        </MotionText>{" "}
                        to our clients, their guests and each other. We adhere
                        to the latest safety and health regulations. The entire
                        Aevias team has been certified on Pandemic On-Site
                        Protocols.
                      </Text>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>
      </Box>

      {/* <Box pb="12rem" as="section">
        <Box mb="40px" as="section" className="wrapper-md">
          <InView triggerOnce threshold={0.5}>
            {({ inView, ref }) => (
              <Box position="relative" ref={ref}>
                {inView && (
                  <Box position="relative" zIndex="2">
                    <Box display="flex" flexDir="column">
                      <Text as="h2" className="ae-title-one" mb="16">
                        Some of Our Clients
                      </Text>
                      <Grid
                        gridTemplateColumns={{
                          base: "none",
                          md: "repeat(4, 1fr)",
                        }}
                        gridTemplateRows={{
                          base: "repeat(4, auto)",
                          md: "none",
                        }}
                        gridColumnGap={{ base: 0, md: "1rem" }}
                        gridRowGap={{ base: "1rem", md: 0 }}
                        alignItems="center"
                      >
                        <img
                          src="/img/ae-vw.png"
                          alt="Aevias Volkswagen Partner"
                        />
                        <img src="/img/ae-audi.png" alt="Aevias Audi Partner" />
                        <img
                          src="/img/ae-nissan.png"
                          alt="Aevias Nissan Partner"
                        />
                        <img
                          src="/img/ae-hootsuite.png"
                          alt="Aevias Hoot Suite Partner"
                        />
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </InView>
        </Box>
        <Box className="wrapper-xl">
          <InView triggerOnce threshold={0.5}>
            {({ inView, ref }) => (
              <Box position="relative" ref={ref}>
                {inView && (
                  <Grid
                    gridTemplateColumns={{ base: "none", md: "repeat(4, 1fr)" }}
                    gridTemplateRows={{ base: "repeat(4, auto)", md: "none" }}
                    gridColumnGap={{ base: 0, md: "1rem" }}
                    gridRowGap={{ base: "1rem", md: 0 }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      style={{ margin: "auto", marginTop: "40px" }}
                      src="/img/ae-teck.png"
                      alt="Aevias Teck partner"
                    />
                    <img
                      style={{ margin: "auto", marginTop: "40px" }}
                      src="/img/ae-bc.png"
                      alt="Aevias British Columbia Partner"
                    />
                    <img
                      style={{ margin: "auto" }}
                      src="/img/ae-avisowealth.png"
                      alt="Aevias Aviso Wealth Partner"
                    />
                    <img
                      style={{ margin: "auto" }}
                      src="/img/ae-bctrcr.png"
                      alt="Aevias Technical and Research Committee on Reclamation Partner"
                    />
                  </Grid>
                )}
              </Box>
            )}
          </InView>
        </Box>
      </Box> */}
    </Layout>
  );
};

export default WhyAeviasPage;
